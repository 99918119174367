import React from "react"
import tw from "twin.macro"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "@components/layout"
import Seo from "@components/seo"
import Section from "@components/section"
import Container from "@components/container"
import Anchor from "@components/anchor"
import { purpleButton } from "@styles/ui"
import InlineVideo from "@components/inlineVideo"

import microphone from "@images/icons/microphone-broadcast.svg"
import radio from "@images/icons/radio-pulse.svg"
// import videoPlaceholder from "@images/broadcast/video-placeholder.jpg"

const BroadcastPage = ({ location, data }) => {
  const experts = [
    {
      name: "Paul Doghramji, MD",
      copy: (
        <>
          <strong>Senior Family Physician,</strong>
          <br />
          Collegeville Family Practice
          <br />
          <strong>Medical Director,</strong> Ursinus College
        </>
      ),
      location: "Collegeville, PA",
      image: data.paul,
    },
    {
      name: (
        <>
          Christopher R. Mohr,
          <br />
          PhD, RD
        </>
      ),
      copy: (
        <>
          <strong>
            Registered Dietitian Nutritionist
            <br />
            Co-Founder,
          </strong>{" "}
          Mohr Results, Inc
        </>
      ),
      location: "Louisville, KY",
      image: data.christopher,
    },
    {
      name: "William B. White, MD",
      copy: (
        <>
          <strong>
            Medical and Scientific Consultant
            <br />
            Professor Emeritus of Medicine,
          </strong>
          <br />
          Cardiology Center
          <br />
          University of Connecticut School of&nbsp;Medicine
        </>
      ),
      location: "Farmington, CT",
      image: data.william,
    },
  ]

  return (
    <Layout location={location} minifiedHeader minifiedFooter noVA>
      {/* TODO: metadata */}
      <Seo
        title="Watch the Exclusive Video on Lower‑Sodium Oxybate in Narcolepsy Patient"
        description="Watch the video to learn from healthcare providers regarding the importance of monitoring sodium intake for patients with narcolepsy."
        location={location}
      />
      <Section bgColour="grey">
        <Container addedStyles={tw`text-center text-white mt-[20px]`}>
          <h1 tw="text-[26px] line-height[120%] mb-[30px] xl:(text-[40px] mb-[25px])">
            An <i>Exclusive Broadcast</i> on <br tw="hidden xl:block" />
            the{" "}
            <strong tw="font-semibold">
              Role of a Lower&#8209;Sodium Oxybate
            </strong>
            <br tw="hidden xl:block" /> in Patients with Narcolepsy
          </h1>
          <p tw="text-base mb-[25px] xl:(mx-auto max-w-[700px])">
            Presented by XYWAV, indicated for the treatment of cataplexy or
            excessive daytime sleepiness (EDS) in patients 7 years of age and
            older with narcolepsy.
          </p>
          <img
            tw="mb-[30px] w-[60px] mx-auto xl:mb-[25px]"
            src={radio}
            alt=""
          />
          <h2 tw="uppercase text-2xl font-medium mb-[15px] xl:(mb-[25px] text-4xl)">
            Multidisciplinary Perspectives
          </h2>
          <div tw="w-[169px] h-[5px] purpleToRedGradient mx-auto mb-[30px] xl:(mb-[25px])" />
          <p tw="text-base mb-[30px] xl:mb-[50px]">
            Hear other healthcare providers from a{" "}
            <strong>range of disciplines</strong> discuss the importance of
            <br tw="hidden xl:block" />{" "}
            <strong>
              <i>monitoring sodium intake</i>
            </strong>{" "}
            for patients with narcolepsy.
          </p>
          <div tw="w-full max-w-[970px] mx-auto">
            <InlineVideo
              wistiaId="r7sevfq1vw"
              thumbnail={data.videoPlaceholder}
              singleCol
              eyebrowText={false}
              videoName="Multidisciplinary Perspectives On the Role of a Low-Sodium Oxybate in Patients with Narcolepsy"
            />
          </div>
        </Container>
      </Section>

      <Section bgColour="grey" addedStyles={tw`pt-[0 !important]`}>
        <Container addedStyles={tw`text-center text-white`}>
          <img
            tw="mb-[15px] w-[64px] mx-auto xl:w-[101px]"
            src={microphone}
            alt=""
          />
          <h2 tw="uppercase text-2xl font-medium mb-[15px] xl:(mb-[25px] text-4xl)">
            Meet The Experts
          </h2>
          <div tw="w-[169px] h-[5px] purpleToRedGradient mx-auto mb-[50px]" />

          <ul tw="flex flex-col items-center xl:(flex-row justify-between) ">
            {experts.map((expert, index) => (
              <li
                key={`expert-${index}`}
                tw="w-[375px] pb-10 last-of-type:pb-0  xl:pb-0"
              >
                <GatsbyImage
                  image={getImage(expert.image)}
                  tw="mb-[15px] w-[183px]"
                />
                <h3 tw="text-4xl font-semibold mb-[15px]">{expert.name}</h3>
                <p tw="text-base mb-[15px] px-2">{expert.copy}</p>
                <p tw="text-base italic">{expert.location}</p>
              </li>
            ))}
          </ul>
          <p tw="mt-12 text-[12px]">Compensated for their time.</p>
        </Container>
      </Section>

      <Section bgColour="grey">
        <Container addedStyles={tw`text-center text-white`}>
          <Anchor
            link="/narcolepsy/prescriber-resources/"
            addedStyles={[
              purpleButton,
              tw`relative max-w-[368px] mb-[22px] mx-auto py-2.5 px-11`,
              tw`hover:(bg-white text-merlin)`,
            ]}
          >
            XYWAV Support & Resources
          </Anchor>
        </Container>
      </Section>
    </Layout>
  )
}

export default BroadcastPage

export const pageQuery = graphql`
  query {
    paul: file(relativePath: { eq: "broadcast/paul.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    christopher: file(relativePath: { eq: "broadcast/christopher.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    william: file(relativePath: { eq: "broadcast/william.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    videoPlaceholder: file(relativePath: { eq: "broadcast/thumbnail.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
  }
`
